import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import StatusInput from "common/components/StatusInput/StatusInput";
import * as R from "ramda";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  inputFormControl: {
    width: "100%",
    marginBottom: "1rem",
  },
}));
const ManagePolicies = ({ formType, isEdit }) => {
  const classes = useStyles();
  const [formData, setformData] = useState({});
  const onChange = (event) => {
    const [value, name] = R.paths(
      [
        ["target", "value"],
        ["target", "name"],
      ],
      event
    );
    const updatedformData = R.assoc(name, value, formData);
  };
  const updatePropertiesList = (propertiesList) => {};
  return (
    <form>
      <FormControl className={classes.inputFormControl}>
        <TextField
          variant="outlined"
          name="policyName"
          label="Policy Name"
          value={formData.policyName}
          onChange={onChange}
        />
      </FormControl>
      <FormControl variant="outlined" className={classes.inputFormControl}>
        <InputLabel id="demo-simple-select-outlined-label">Category</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={formData.category}
          onChange={onChange}
          label="category"
          name="category"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={"hr"}>HR</MenuItem>
          <MenuItem value={"admin"}>Administrative</MenuItem>
          <MenuItem value={"marketing"}>Marketing & Communication</MenuItem>
          <MenuItem value={"academic"}>Academic</MenuItem>
          <MenuItem value={"admissions"}>Admissions</MenuItem>
          <MenuItem value={"it"}>IT</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined" className={classes.inputFormControl}>
        <InputLabel id="demo-simple-select-outlined-label">
          Academic Year
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={formData.academicYear}
          onChange={onChange}
          label="Academic Year"
          name="academicYear"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={"2016-2017"}>2016-2017</MenuItem>
          <MenuItem value={"2017-2018"}>2017-2018</MenuItem>
          <MenuItem value={"2018-2019"}>2018-2019</MenuItem>
          <MenuItem value={"2019-2020"}>2019-2020</MenuItem>
          <MenuItem value={"2020-2021"}>2020-2021</MenuItem>
          <MenuItem value={"2021-2022"}>2021-2022</MenuItem>
          <MenuItem value={"2022-2023"}>2021-2022</MenuItem>
          <MenuItem value={"2023-2024"}>2021-2022</MenuItem>
          <MenuItem value={"2024-2025"}>2021-2022</MenuItem>
          <MenuItem value={"2025-2026"}>2021-2022</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined" className={classes.inputFormControl}>
        <InputLabel id="demo-simple-select-outlined-label">School</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={formData.school}
          onChange={onChange}
          label="School"
          name="school"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined" className={classes.inputFormControl}>
        <InputLabel id="demo-simple-select-outlined-label">
          Class Name
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={formData.classId}
          onChange={onChange}
          label="Class name"
          name="Class"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
      {!isEdit && <StatusInput formType={formType} />}
    </form>
  );
};
export default ManagePolicies;
